
import { defineComponent, onMounted } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "KtWidget5",
  components: {
    Dropdown1,
  },
  props: {
    widgetClasses: {type: String, default: ""},
  },
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
    });
  },
});
